.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  background-color: #292a33;
  z-index: 9999;
}

.cookie-consent-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.cookie-consent-text {
  margin: 0;
}

.cookie-consent-btns-wrapper {
  display: flex;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .cookie-consent-wrapper {
    flex-direction: column;
    align-items: normal;
  }

  .cookie-consent-btns-wrapper {
    width: 100%;
    justify-content: space-between;
  }
}