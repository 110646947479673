.services-about {
  margin: 60px 0 0 0;
  padding: 30px 0;
  background-color: #232d51;
}

.services {
  padding: 60px 0 140px 0;
  background-color: var(--bs-body-bg);
  z-index: 20;
  position: relative;
}

.services-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.services-img-wrapper {
  width: 40%;
}

.services-img-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  border: 2px solid var(--bs-body-bg);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.services-text-wrapper {
  width: 55%;
}

.services-text-wrapper h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  background-image: linear-gradient(45deg, var(--bs-primary), #edf2ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-text-wrapper p {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

@media only screen and (max-width: 991px) {
  .services-wrapper {
    flex-direction: column;
  }

  .services-text-wrapper {
    width: 100%;
    margin-bottom: 1rem;
    order: 1;
  }

  .services-img-wrapper {
    width: 100%;
    order: 2;
  }
}